module directives {
    export module logistics {
        interface IConsignmentMetricsScope extends ng.IScope {
            consignmentMetricsData: interfaces.consignment.IConsignmentMetricsData[];
            isLoading : boolean;
            YearList: Array<interfaces.applicationcore.IKeyValue>;
            MonthList: Array<interfaces.applicationcore.IKeyValue>;
            LoadConsignmentMetricsData();
            selectedYear : number;
            selectedMonth: number;
            currentMonth: string;
            priorMonth: string;
        }

        export class consignmentMetricsDirective implements ng.IDirective {
            templateUrl = 'templates/modules/applicationmain/logistics/consignmentMetricsView.html';
            scope = {
            }

            public consignmentMetricsData: interfaces.consignment.IConsignmentMetricsData[];

            

            constructor(
                private generalService: interfaces.applicationcore.IGeneralService,
                private $state: ng.ui.IStateService,
                private bsLoadingOverlayService,
                private consignmentService: interfaces.consignment.IConsignmentService,
            ) {
                   
            }

            link = ($scope: IConsignmentMetricsScope, $element: ng.IAugmentedJQuery, $state) => {

                var year = new Date();

                $scope.YearList = new  Array<interfaces.applicationcore.IKeyValue>();
                $scope.YearList.push({key: year.getFullYear().toString() ,value: year.getFullYear()});
                $scope.YearList.push({key: (year.getFullYear()-1).toString() ,value: year.getFullYear()-1});
                $scope.YearList.push({key: (year.getFullYear()-2).toString() ,value: year.getFullYear()-2});

                $scope.MonthList = new  Array<interfaces.applicationcore.IKeyValue>();
                $scope.MonthList.push({key:"January",value:1});
                $scope.MonthList.push({key:"February",value:2});
                $scope.MonthList.push({key:"March",value:3});
                $scope.MonthList.push({key:"April",value:4});
                $scope.MonthList.push({key:"May",value:5});
                $scope.MonthList.push({key:"June",value:6});
                $scope.MonthList.push({key:"July",value:7});
                $scope.MonthList.push({key:"August",value:8});
                $scope.MonthList.push({key:"September",value:9});
                $scope.MonthList.push({key:"October",value:10});
                $scope.MonthList.push({key:"November",value:11});
                $scope.MonthList.push({key:"December",value:12});

                $scope.selectedYear= year.getFullYear();
                $scope.selectedMonth = year.getMonth()+1; //zero based!!

                var months    = ['January','February','March','April','May','June','July','August','September','October','November','December'];
                
            

                $scope.LoadConsignmentMetricsData = () => {
                    $scope.isLoading = true;
                    return this.consignmentService.GetConsignmentMetricsData($scope.selectedYear,$scope.selectedMonth).query({
                    }, (resultList) => {
                        $scope.consignmentMetricsData = resultList;
                        $scope.currentMonth =  months[$scope.selectedMonth-1];  
                        $scope.priorMonth =  months[$scope.selectedMonth-2];  
                        $scope.isLoading = false;
                    }).$promise;
                }
               
               $scope.LoadConsignmentMetricsData();
                
            }

            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, $state,
                    bsLoadingOverlayService,consignmentService) => new consignmentMetricsDirective(generalService, $state, bsLoadingOverlayService,consignmentService);
                directive.$inject = ["generalService", "$state", "bsLoadingOverlayService","consignmentService"];

                return directive;
            }
        }

        angular.module("app").directive("gtsConsignmentMetrics", consignmentMetricsDirective.factory());
    }
}